$castlabs-ui-asset-prefix: '../..';
@import '@castlabs/ui/src/styles/ui';

// --- global styles -----------------------------------------------------------

@keyframes fadeinout {
  0%,
  100% {
    background-color: $color-ci-white;
  }

  50% {
    background-color: $color-ci-blue;
  }
}

.cl-wizard-steps li.active.auto-transition::after {
  animation: fadeinout 1s infinite;
}

.cl-dropzone {
  min-height: 4em;
}
